import type { InferGetStaticPropsType } from 'next'
import parse from 'html-react-parser'
import DOMPurify from 'isomorphic-dompurify'
import { getCollection } from '@framework/product'
import { getPage } from '@framework/page'
import { getConfig } from '@framework/api/config'
import { Layout, Hero } from '@components/common'
import { ProductSlider } from '@components/product'
import { ProductCollectionGrid } from '@components/product'
import getAllCollections from '@framework/collection/get-all-collections'
import SEO from '@components/common/SEO/SEO'
import { HeroSlide } from '@components/common/Hero/Hero'

export async function getStaticProps() {
  const config = getConfig()
  const collections = await getAllCollections(config)
  /**
   * TODO: Move from sale slug being hard-coded
   *
   * This is linked to a static customer Shopify collection that Shasta
   * can change the products of themselves.
   */
  const { collection: saleCollection } = await getCollection({
    config,
    variables: { slug: 'home-page-sale' },
  })
  /**
   * TODO: Move from the hero slug being hard-coded
   */
  /**
   * We have two pages for managing the hero image, one for production
   * and one for staging/development.
   */
  const homePageHeroSlug =
    process.env.NEXT_PUBLIC_DEPLOYMENT === 'production'
      ? 'home-page-hero-v2'
      : 'home-page-hero-v2-staging'
  const { page: homePageHero } = await getPage({
    config,
    variables: { slug: homePageHeroSlug },
  })

  return {
    props: {
      collections,
      saleCollection,
      homePageHero,
    },
    revalidate: 60 * 5,
  }
}

export default function Home({
  collections,
  saleCollection,
  homePageHero,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  /**
   * Because we are limited on actual data we can store on a page w/o adding
   * an app, we have to get a little tricky with how we control the home page
   * slider
   *
   * You can insert images anywhere into the WYSIWYG editor and it extracts
   * out the images, in order, even if they are nested in other elements.
   *
   * It then looks at the image props and uses the image src for the slide image
   * url and it uses the alt as catch-all field for the other hero data. It's
   * pipe delimited, and is currently in the format title|subtitle|link|button text
   *
   * If you want to not use a feature, like not having a subtitle, you'd leave
   * the pipes in for it, but just leave it blank: i.e: title||link|button text
   */
  const homePageHeroBody = homePageHero?.body ?? ''
  const homePageHeroBodyClean = DOMPurify.sanitize(homePageHeroBody)
  const homePageHeroBodyParsed = parse(homePageHeroBodyClean)
  let homePageHeroSlides = [] as HeroSlide[]

  const parseHeroEl = (el: JSX.Element) => {
    if (el.type === 'img') {
      const slideUrl = el.props.src.replace(/_\d*?x\d*/g, '') ?? ''
      const slideTitle = el.props.alt.split('|')[0]?.trim() ?? ''
      const slideSubtitle = el.props.alt.split('|')[1]?.trim() ?? ''
      const slideLink = el.props.alt.split('|')[2]?.trim() ?? ''
      const slideButtonText = el.props.alt.split('|')[3]?.trim() ?? ''
      const slideMobileUrl = el.props.alt.split('|')[4]?.trim() ?? ''

      return homePageHeroSlides.push({
        // Strip the Shopify CDN sizing that gets added in so we have full-size images :)
        url: slideUrl,
        title: slideTitle,
        subtitle: slideSubtitle,
        link: slideLink,
        buttonText: slideButtonText,
        mobileUrl: slideMobileUrl
      })
    } else if (el?.props?.children) {
      if (Array.isArray(el.props.children)) {
        el.props.children.forEach((c: JSX.Element) => {
          parseHeroEl(c)
        })
      } else {
        parseHeroEl(el.props.children)
      }
    }
  }

  if (Array.isArray(homePageHeroBodyParsed)) {
    homePageHeroBodyParsed.forEach((el) => {
      parseHeroEl(el)
    })
  } else if (typeof homePageHeroBodyParsed !== 'string') {
    parseHeroEl(homePageHeroBodyParsed)
  }

  return (
    <>
      {homePageHero && <Hero slides={homePageHeroSlides} />}
      {collections.length > 0 && (
        <ProductCollectionGrid collections={collections} />
      )}
      {saleCollection && saleCollection.products?.length > 0 && (
        <ProductSlider
          title="Shop Sale Products"
          products={saleCollection.products}
        ></ProductSlider>
      )}
      <SEO
        title="TS Pool Supply Salt Cell & Chlorine Generator Replacement Leader"
        description={'Lowest pricing on Pentair IC40 salt cell chlorine generators. Fast shipping. Central Phoenix location for easy local pick ups.'}
        openGraph={{
          type: 'website',
          title: 'TS Pool Supply Salt Cell & Chlorine Generator Replacement Leader',
          description: 'Lowest pricing on Pentair IC40 salt cell chlorine generators. Fast shipping. Central Phoenix location for easy local pick ups.',
          images: [
            {
              url: '/static_images/ts-pool-supply-og-pool.jpg',
              width: 1280,
              height: 720,
              alt: 'TS Pool Supply',
            },
          ],
        }}
      />
    </>
  )
}

Home.Layout = Layout
